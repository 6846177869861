import _ from "lodash";
import { ID_GLOBALS_DB, PERMISSIONS_DEFAULT } from "@/dbGlobals";
import {
  post as postDx,
  getByIds as getByIdsDx,
  getByCollectionName as getByCollectionNameDx,
  put as putDx,
  putUser as putUserDx,
  patch as patchDx,
  bulkPatch as bulkPatchDx,
  delReal as delRealDx,
  delVirtual as delVirtualDx,
} from "@/client-side-db2/dexieApis";
import { getByCollectionNames as getByCollectionNamesUtilsCS } from "@/utils/utilsCS";
import { syncDB } from "@/client-side-db2/syncDB";
import { setObjProp as setObjPropMutators } from "@/lib/mutators";
import {
  hasProperty,
  getNowIsoStr,
  getMinSec,
  possiblySetPermissions,
  checkDocFieldsLength,
} from "@/utils/utilsCSAndSS";
import { log } from "@/utils/log";
import { MISSING_OBJ } from "@/globalConstants";

/* --------------------------------------------------------------------------
    1) Helpers for Error Handling & Data Transformations
   -------------------------------------------------------------------------- */

/**
 * A minimal helper that wraps a function call in a try/catch block and returns
 * either the function's result or an { error: { msg } } object.
 *
 * @param {Function} fn - An async function that performs some operation.
 * @returns {Object} The result of fn() or { error: { msg: string } } if an exception is thrown.
 */
async function safeOperation(fn) {
  try {
    return await fn();
  } catch (err) {
    return { error: { msg: err.toString() } };
  }
}

/**
 * Applies final steps after a Dexie API call:
 *  - If the Dexie operation doesn't already handle sync, we can skip or do partial sync logic.
 *  - Refresh "globalsCS" by calling updateGlobalsCS, merging any error returned from it into the original response.
 *
 * @param {Function} dexieFn - The Dexie-based function that modifies the DB (e.g. postDx, patchDx).
 * @param {Function} setGlobalsCS - The setGlobalCS function from the DbInfoProvider, to refresh the UI.
 * @param {Object} operationArgs - The arguments to pass to the Dexie-based function.
 * @param {boolean} doPermissions - Whether to call possiblySetPermissions on the doc(s).
 * @returns {Object} The final result object, merging both the Dexie operation result and the updateGlobalsCS result.
 */
async function dbOperationAndRefreshGlobals(
  dexieFn,
  setGlobalsCS,
  operationArgs,
  doPermissions = false
) {
  // Possibly set doc-level permissions if needed
  if (doPermissions) {
    const { cN, obj, objs } = operationArgs;
    possiblySetPermissions({ cN, obj, objs });
  }

  // Check if the doc fields length exceeds the maximum limit
  const { obj, objs } = operationArgs;
  if (obj) {
    const { isValid, fieldsToTrim } = checkDocFieldsLength(obj);
    if (!isValid) {
      throw new Error(
        `Doc fields length exceeds the maximum limit. Please trim the fields: ${fieldsToTrim
          .map((field) => field.field)
          .join(", ")}`
      );
    }
  }
  if (objs) {
    for (const doc of Array.isArray(objs) ? objs : [objs]) {
      const { isValid, fieldsToTrim } = checkDocFieldsLength(doc);
      if (!isValid) {
        throw new Error(
          `Doc fields length exceeds the maximum limit. Please trim the fields: ${fieldsToTrim
            .map((field) => field.field)
            .join(", ")}`
        );
      }
    }
  }

  // Instruct Dexie to skip awaiting its internal sync calls, so we control it here
  operationArgs.awaitSyncDB = false;

  // Step 1: Perform the Dexie operation
  let res = await safeOperation(() => dexieFn(operationArgs));

  // Step 2: If no Dexie error, update the UI state by re-fetching the globals record
  if (!res.error) {
    const onChangeRes = await updateGlobalsCS(setGlobalsCS);
    // If onChangeRes has an error, prefer that error
    if (onChangeRes.error) {
      res = onChangeRes;
    }
  }
  return res;
}

/**
 * Fetches "globals_db" from Dexie and updates the React state using setGlobalsCS.
 * This causes a re-render of any component depending on globalsCS.
 *
 * @param {Function} setGlobalsCS - The setGlobalCS function from DbInfoProvider.
 * @returns {Object} A result with either .data or .error
 */
async function updateGlobalsCS(setGlobalsCS) {
  log.trace("Called getByIdsDx(ID_GLOBALS_DB) at: ", getMinSec(getNowIsoStr()));
  const res = await getByIdsDx({ objId: ID_GLOBALS_DB });
  log.trace("Return getByIdsDx(ID_GLOBALS_DB) at: ", getMinSec(getNowIsoStr()));

  const globalsCS = res?.data?.obj;
  log.trace("globalsCS.modified: ", getMinSec(globalsCS?.modified));
  if (globalsCS) {
    setGlobalsCS(globalsCS);
  }
  return res;
}

/**
 * Replaces references to image IDs with actual fileInfo content for images,
 * then ensures "missing" doc placeholders are substituted for undefined objects.
 *
 * @param {Object} data - An object with shape { obj, objs } from Dexie results
 * @param {Object} operationArgs - Could contain objId, objIds, etc. for missing placeholders
 * @returns {Object} The updated data object with image info replaced & missing docs replaced.
 */
async function transformData(data, operationArgs) {
  // 1) Replace image references with fileInfo
  data = await findImageInfoAndReplace(data);
  // 2) Replace missing docs with MISSING_OBJ placeholders
  data = replaceMissingObjsWithPlaceholderObjs(operationArgs, data);
  return data;
}

/* --------------------------------------------------------------------------
    2) Internal Utilities for Image Info Replacement & Missing Doc Placeholders
   -------------------------------------------------------------------------- */

async function replaceImageIdWithFileInfo(obj) {
  // Only proceed if the object is truthy
  if (!obj) return obj;

  for (let key in obj) {
    if (
      hasProperty(obj, key) &&
      obj[key] !== null &&
      obj[key] !== undefined &&
      key.toLowerCase().includes("imageinfo")
    ) {
      // If there's already an imageUrl, skip
      if (
        hasProperty(obj[key], "fileInfo") &&
        hasProperty(obj[key].fileInfo, "imageUrl")
      ) {
        continue;
      }

      let objIdsArray = [];
      if (Array.isArray(obj[key])) {
        objIdsArray = obj[key]?.map((info) => info.objId);
      } else {
        objIdsArray = [obj[key].objId];
      }

      if (!objIdsArray || objIdsArray.length === 0) {
        continue;
      }

      const imageInfoRes = await getByIdsDx({ objIds: objIdsArray });
      if (!imageInfoRes?.data?.objs) {
        continue; // If we failed to fetch image info
      }

      // Build an array of file info objects
      let imageInfoObj = imageInfoRes.data.objs.map((img) => ({
        objId: img._id,
        fileInfo: {
          dirName: img.dirName,
          fileName: img.fileName,
          fullPath: img.fullPath,
          imageUrl: img.imageUrl,
          creator: img.creator,
          displayName: img.displayName,
          originalUrl: img.originalUrl,
        },
        thumbnailBase64: img.thumbnailBase64,
        status: img.status,
        creator: img.creator,
      }));

      // If the original property was an array, replace with array; else single
      obj[key] = Array.isArray(obj[key]) ? imageInfoObj : imageInfoObj[0];
    }
  }
  return obj;
}

async function findImageInfoAndReplace(data) {
  if (!data) return data;

  // For single obj
  if (data.obj) {
    data.obj = await replaceImageIdWithFileInfo(data.obj);
  }

  // For an array of objs
  if (Array.isArray(data.objs) && data.objs.length > 0) {
    let newObjArray = [];
    for (let i = 0; i < data.objs.length; i++) {
      const tempObj = await replaceImageIdWithFileInfo(data.objs[i]);
      newObjArray.push(tempObj);
    }
    data.objs = newObjArray;
  }
  return data;
}

/**
 * Ensure we have placeholders for any missing doc objects in the .obj or .objs array.
 *
 * @param {Object} operationArgs - Typically includes objId or objIds
 * @param {Object} data - The { obj, objs } structure from Dexie results
 * @returns The same data object, with possibly replaced placeholders
 */
function replaceMissingObjsWithPlaceholderObjs(operationArgs, data) {
  const { objId, objIds } = operationArgs;
  if (!data) {
    return data;
  }
  if (!data?.obj) {
    data.obj = _.cloneDeep(MISSING_OBJ);
    data.obj._id = objId || objIds?.[0];
  }

  if (Array.isArray(data?.objs) && data.objs.length > 0) {
    for (let i = 0; i < data.objs.length; i++) {
      let docItem = data.objs[i];
      if (!docItem) {
        docItem = _.cloneDeep(MISSING_OBJ);
        docItem._id = objIds?.[i] || objId;
        data.objs[i] = docItem;
      }
    }
  }
  return data;
}

/* --------------------------------------------------------------------------
    3) Main Exports: Public Functions Wrapping Dexie Apis + Extra Logic
   -------------------------------------------------------------------------- */

/**
 * Create new doc(s) in Dexie, then re-fetch "globals_db" to update React state.
 * Also calls possiblySetPermissions on the doc(s).
 *
 * @param {Function} setGlobalsCS - from DbInfoProvider
 * @param {Object} operationArgs - e.g. { cN, obj or objs, ... }
 * @returns {Object} The result of postDx or an error object
 */
async function post(setGlobalsCS, operationArgs) {
  return dbOperationAndRefreshGlobals(
    postDx,
    setGlobalsCS,
    operationArgs,
    true
  );
}

/**
 * Retrieve doc(s) by ID from Dexie, run transformations to fill image info,
 * handle missing objects, and return the final data.
 *
 * @param {Function} setGlobalsCS - from DbInfoProvider (unused here, but kept for signature consistency)
 * @param {Object} operationArgs - e.g. { objId, objIds }
 * @returns {Object} The final response with data or error
 */
async function getByIds(setGlobalsCS, operationArgs) {
  // If no IDs, just return an empty result
  if (Array.isArray(operationArgs?.objIds) && operationArgs.objIds.length < 1) {
    log.bug("getByIds() called with empty objIds array. Fix this.");
    return { data: { objs: [], obj: undefined } };
  }

  // Perform safe call, then transform data
  let res = await safeOperation(() => getByIdsDx(operationArgs));
  if (!res.error && res.data) {
    res.data = await transformData(res.data, operationArgs);
  }
  return res;
}

/**
 * Retrieve docs from a single collection by optional filter/sort/pagination,
 * then transform them (image info, placeholders).
 *
 * @param {Function} setGlobalsCS
 * @param {Object} operationArgs - e.g. { cN, filterFn, sortFn, pagination, includeDeleted }
 * @returns {Object} The final response with data or error
 */
async function getByCollectionName(setGlobalsCS, operationArgs) {
  const res = await safeOperation(() => getByCollectionNameDx(operationArgs));
  if (!res.error && res.data) {
    res.data = await transformData(res.data, operationArgs);
  }
  return res;
}

/**
 * Insert or replace a single doc in Dexie, then refresh React's global state.
 *
 * @param {Function} setGlobalsCS
 * @param {Object} operationArgs - e.g. { obj, cN }
 * @returns {Object} Dexie result or error
 */
async function put(setGlobalsCS, operationArgs) {
  return dbOperationAndRefreshGlobals(putDx, setGlobalsCS, operationArgs);
}

/**
 * Specifically updates or inserts a "users" doc in Dexie, then refreshes global state.
 *
 * @param {Function} setGlobalsCS
 * @param {Object} operationArgs - Must parse to cN = "users" from obj._id
 * @returns {Object} Dexie result or error
 */
async function putUser(setGlobalsCS, operationArgs) {
  return dbOperationAndRefreshGlobals(putUserDx, setGlobalsCS, operationArgs);
}

/**
 * Partially update one doc in Dexie, with special handling for imageInfos, then refresh.
 * Also calls possiblySetPermissions if we want to unify permission logic.
 *
 * @param {Function} setGlobalsCS
 * @param {Object} operationArgs - e.g. { obj: {...}, ... }
 * @returns {Object} Dexie result or error
 */
async function patch(setGlobalsCS, operationArgs) {
  // If we want consistent permission logic on patch, uncomment below:
  // possiblySetPermissions({ cN: getCn(operationArgs.obj._id), obj: operationArgs.obj });
  // Additional special handling for imageInfos remains below.
  try {
    // If we are patching imageInfos, we do partial merges with existing doc
    if (
      operationArgs.obj?.imageInfos ||
      operationArgs.obj?.imageInfosLayoutName
    ) {
      const currentObj = await getByIdsDx({ objId: operationArgs.obj._id });
      const currentData = currentObj?.data?.obj;

      operationArgs.obj = {
        ...operationArgs.obj,
        imageInfosLayoutName:
          operationArgs.obj.imageInfosLayoutName ||
          currentData.imageInfosLayoutName,
        imageInfos: (
          operationArgs.obj.imageInfos || currentData.imageInfos
        ).map((newInfo, index) => {
          const currentImageInfos = currentData.imageInfos || [];

          if (typeof newInfo === "string" || newInfo[0]) {
            // This implies an older code path with a string or array reference
            const existingImage = currentImageInfos[index];
            return {
              objId: existingImage?.objId,
              fileInfo: existingImage?.fileInfo || {},
            };
          }

          const existingImage = currentImageInfos.find(
            (img) => img.objId === newInfo.objId
          );

          return {
            objId: newInfo.objId || existingImage?.objId,
            fileInfo: {
              ...(existingImage?.fileInfo || {}),
              ...(newInfo.fileInfo || {}),
              imageUrl:
                newInfo.fileInfo?.imageUrl || existingImage?.fileInfo?.imageUrl,
              creator:
                newInfo.fileInfo?.creator || existingImage?.fileInfo?.creator,
              displayName:
                newInfo.fileInfo?.displayName ||
                existingImage?.fileInfo?.displayName,
              fileName:
                newInfo.fileInfo?.fileName || existingImage?.fileInfo?.fileName,
            },
          };
        }),
      };
    }
  } catch (imgErr) {
    log.error("Error handling imageInfos in patch:", imgErr);
    // We'll continue anyway to run the normal patch logic
  }

  // Then do the Dexie operation + updateGlobalsCS
  return dbOperationAndRefreshGlobals(patchDx, setGlobalsCS, operationArgs);
}

/**
 * Partially update multiple docs in Dexie at once, then refresh.
 *
 * @param {Function} setGlobalsCS
 * @param {Object} operationArgs - e.g. { objs: [...], ... }
 * @returns {Object} Dexie result or error
 */
async function bulkPatch(setGlobalsCS, operationArgs) {
  return dbOperationAndRefreshGlobals(bulkPatchDx, setGlobalsCS, operationArgs);
}

/**
 * Permanently remove doc(s) from Dexie, then refresh.
 *
 * @param {Function} setGlobalsCS
 * @param {Object} operationArgs - e.g. { objId, objIds }
 * @returns {Object} Dexie result or error
 */
async function delReal(setGlobalsCS, operationArgs) {
  return dbOperationAndRefreshGlobals(delRealDx, setGlobalsCS, operationArgs);
}

/**
 * Mark doc(s) as "deleted" in Dexie (virtual delete) so they can be removed later, then refresh.
 *
 * @param {Function} setGlobalsCS
 * @param {Object} operationArgs - e.g. { objId, objIds }
 * @returns {Object} Dexie result or error
 */
async function delVirtual(setGlobalsCS, operationArgs) {
  return dbOperationAndRefreshGlobals(
    delVirtualDx,
    setGlobalsCS,
    operationArgs
  );
}

/**
 * Extra function that does not directly correspond to a Dexie call.
 * It fetches data across multiple collection names, then transforms the result (image info, placeholders).
 *
 * @param {Function} setGlobalsCS
 * @param {Object} operationArgs - Typically { cNs, filterFn, sortFn, pagination, ... }
 * @returns {Object} The final data or error
 */
async function getByCollectionNames(setGlobalsCS, operationArgs) {
  let res = await safeOperation(() =>
    getByCollectionNamesUtilsCS(operationArgs)
  );
  if (!res.error && res.data) {
    res.data = await transformData(res.data, operationArgs);
  }
  return res;
}

/* --------------------------------------------------------------------------
    4) Additional Logic for Setting Object Properties & Possibly Updating Cur User
   -------------------------------------------------------------------------- */

/**
 * Possibly update the current user context if the patched object is the same as userMongoInfo.userMongo.
 *
 * @param {Object} dbInfo - The object with DB info, if we need to do more Dexie calls.
 * @param {Object} userMongoInfo - The userMongo context, with updateUserMongo() method.
 * @param {Object} res - The result object from a setObjProp call to Dexie.
 */
async function possiblyUpdateCurUser(dbInfo, userMongoInfo, res) {
  if (!res || res.error) {
    return; // If update failed, do nothing
  }
  const curUserId = userMongoInfo?.userMongo?._id;
  if (res?.data?.obj?._id === curUserId) {
    await userMongoInfo.updateUserMongo();
  }
}

/**
 * Sets a property on a doc using the setObjPropMutators helper. Then updates Dexie and the user context if needed.
 *
 * @param {Object} dbInfo - Contains e.g. setGlobalsCS, globalsCS, etc.
 * @param {Object} userMongoInfo - The user context for possible update if the doc was the current user.
 * @param {Object} operationArgs - The usual set of { _id, newValue, ... }
 * @returns {Object} The result of setObjPropMutators or error
 */
async function setObjProp(dbInfo, userMongoInfo, operationArgs) {
  log.trace("Enter setObjProp() at: ", getMinSec(getNowIsoStr()));
  let res;

  try {
    // Check if the doc fields length exceeds the maximum limit
    const { newValue, propName } = operationArgs;
    if (newValue && propName) {
      const { isValid, fieldsToTrim } = checkDocFieldsLength({
        [propName]: newValue,
      });
      if (!isValid) {
        throw new Error(
          `Doc fields length exceeds the maximum limit. Please trim the fields: ${fieldsToTrim
            .map((field) => field.field)
            .join(", ")}`
        );
      }
    }

    // 1) Update the doc property via setObjPropMutators
    log.trace("Called setObjPropMutators() at: ", getMinSec(getNowIsoStr()));
    res = await safeOperation(() =>
      setObjPropMutators({ ...operationArgs, dbInfo })
    );
    log.trace("Return setObjPropMutators() at: ", getMinSec(getNowIsoStr()));

    // 2) Possibly update the current user context if it's the same doc
    await possiblyUpdateCurUser(dbInfo, userMongoInfo, res);

    // 3) Refresh the "globals_db" state for the UI
    log.trace("Called updateGlobalsCS() at: ", getMinSec(getNowIsoStr()));
    const onChangeRes = await updateGlobalsCS(dbInfo.setGlobalsCS);
    log.trace("Return updateGlobalsCS() at: ", getMinSec(getNowIsoStr()));
    if (!onChangeRes.error) {
      return res;
    } else {
      return onChangeRes;
    }
  } catch (err) {
    const msg = err.toString();
    return { error: { msg } };
  }
}

/* --------------------------------------------------------------------------
    5) Exported Functions
   -------------------------------------------------------------------------- */
export {
  // Dexie-based operations with refresh
  post,
  getByIds,
  getByCollectionName,
  put,
  putUser,
  patch,
  bulkPatch,
  delReal,
  delVirtual,
  setObjProp, // Additional logic for property setting
  getByCollectionNames, // Non-Dexie convenience function
  // If you want to optionally re-export updateGlobalsCS or transformData, you can:
  updateGlobalsCS,
  transformData,
};
